.fab-shadow {
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.25), 0 4px 6px -4px rgb(0 0 0 / 0.5);
}

.float-left {
  @apply left-4;
}

.float-right {
  @apply right-4;
}

@screen lg {
  .float-left {
    @apply ml-4;
    left: calc(calc(100% - 1024px) / 2);
  }

  .float-right {
    @apply mr-4;
    right: calc(calc(100% - 1024px) / 2)
  }
}

@media (min-width: 1040px) {
  .float-left {
    @apply ml-0;
    left: calc(calc(100% - 1024px) / 2);
  }

  .float-right {
    @apply mr-0;
    right: calc(calc(100% - 1024px) / 2)
  }
}
